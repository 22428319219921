<template>
  <div v-if="showScheduler" class="tcs-modal">
    <div class="scheduler-container" v-show="schedulerContainer">
      <a class="scheduler-exit" id="scheduler-exit" v-on:click="toggleScheduler">
        &#x2715;
      </a>

      <div id="scheduler-main">
        <h1>Get Your Questions Answered Now!</h1>
        <p>
          Have a question you need answered before taking the next step? Our VIP
          Specialists can help! Schedule a quick call now.
        </p>

        <div id="tcs-error" v-if="errorMessages.length">
          <ul>
            <li v-for="e in errorMessages" :key="e">{{ e }}</li>
          </ul>
        </div>

        <form id="LeadGen" @submit.prevent="processForm">
          <input
            id="FirstName"
            name="FirstName"
            placeholder="First Name"
            maxlength="20"
            type="text"
            v-model="FirstName"
            required
          />

          <input
            id="LastName"
            name="LastName"
            placeholder="Last Name"
            maxlength="30"
            type="text"
            v-model="LastName"
            required
          />

          <input
            name="email"
            maxlength="50"
            placeholder="Email"
            type="text"
            v-model="email"
            id="email"
          />

          <input
            id="PhoneNumber"
            name="PhoneNumber"
            placeholder="Phone Number: (555) 555-5555"
            maxlength="14"
            type="text"
            v-model="PhoneNumber"
            @keyup="formatPhone()"
            required
          />

          <DateTime2 @update:date="dateChanged" />

          <input name="submit" type="submit" value="SUBMIT" class="continue" />

          <p>
            We value your
            <a :href="getPrivacyPolicyLink()" target="_blank">privacy</a>. *All time slots are in Eastern Time (ET).
          </p>
        </form>
      </div>
      <div class="scheduler-confirmation">
        <div class="circle-loader">
          <div class="checkmark draw"></div>
        </div>
        <br />
        <p class="confirmation-message"></p>
      </div>
    </div>
  </div>
</template>

<script>
import DateTime2 from "./DatePicker2Component.vue";
import { validateForm, formatPhoneNumber, getPrivacyPolicyLink, submitForm, showConfirmationMessage } from "../utils/helper.js";

export default {
  name: "SchedulerModal",
  data() {
    return {
      FirstName: "",
      LastName: "",
      email: "",
      PhoneNumber: "",
      bestTimeToCall: "",
      errorMessages: [],
      schedulerContainer: true,
    };
  },
  components: {
    DateTime2,
  },
  props: {
    showScheduler: Boolean,
    toggleScheduler: Function,
  },
  methods: {
    dateChanged(value) {
      this.bestTimeToCall = value;
    },

    getPrivacyPolicyLink() {
      return getPrivacyPolicyLink(window.location.hostname);
    },

    formatPhone() {
      this.PhoneNumber = formatPhoneNumber(this.PhoneNumber);
    },

    processForm() {
      const formData = {
        firstName: this.FirstName,
        lastName: this.LastName,
        email: this.email,
        phoneNumber: encodeURIComponent(this.PhoneNumber.trim()),
        bestTimeToCall: new Date(this.bestTimeToCall.replace(/-/g, "/")),
      };
      this.errorMessages = validateForm(formData);

      if (this.errorMessages.length === 0) {
        submitForm(formData)
          .then(() => {
            this.FirstName = "";
            this.LastName = "";
            this.email = "";
            this.PhoneNumber = "";
            this.bestTimeToCall = "";
            this.schedulerContainer = false;
            showConfirmationMessage(formData.bestTimeToCall); 
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
          });
      }
    },
  },
};
</script>
