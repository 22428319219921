<template>
  <div>
    <date-picker2
      id="callTime"
      :overlay="true"
      :minute-interval="15"
      :no-weekends-days="true"
      label="Best Time to Call"
      :no-button-now="true"
      :disabled-hours="disabledHours"
      :disabled-dates="disabledDates"
      :show="show"
      @close="show = false"
      v-model="selectedDate"
      @input="dateChanged"
      :min-date="dateToday"
      :max-date="maxDate"
    />
  </div>
</template>

<script>
import DatePicker2 from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  name: "Date2",
  components: {
    DatePicker2,
  },
  data() {
    return {
      selectedDate: null,
      show: false,
      dateToday: new Date(),
      maxDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000),
      disabledHours: [
        '00', '01', '02', '03', '04', '05', '06', '07', '08', '12',
        '17', '18', '19', '20', '21', '22', '23'
      ],
      disabledDates: [
        '2024-01-15', '2024-02-19', '2024-05-27', '2024-06-19',
        '2024-07-04', '2024-09-02', '2024-11-28', '2024-11-29',
        '2024-11-24', '2024-11-25', '2025-01-01'
      ]
    };
  },
  methods: {
    dateChanged() {
      this.$emit("update:date", this.selectedDate);
    }
  }
};
</script>
